import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TopBar from "../components/TopBar";
import CalendarComp from "../components/CalendarComp";
import TypeOfDelivery from "../components/TypeOfDelivery";
import queryString from "query-string";
import axios from "axios";
import { format, parseISO } from "date-fns";
import { nb } from "date-fns/locale";

const ReturnPage = () => {
  const { search } = useLocation();
  const navigate = useNavigate(); // Instantiate useNavigate
  const [errorMsg, setErrorMsg] = useState(""); // State for holding error messages
  const [phoneError, setPhoneError] = useState(""); //State for holding phone number error
  const [isLoading, setIsLoading] = useState(false);

  //Place store store the wanda order data
  const [wandaOrderData, setWandaOrderData] = useState({
    storageItemIds: [""], // Initialize with default item ID
    type: "PICK_UP",
    orderDetails: {
      deliveryDate: "",
      deliveryTimeSlot: {
        from: "",
        to: "",
      },
      contactInfo: {
        firstName: "",
        lastName: "",
        address: {
          street: "",
          city: "",
          postalCode: "",
          countryCode: "NO", // Default to Norway
        },
        email: "",
        mobilePhone: "",
      },
      locale: "no-NO", // Set locale to no-NO
      extraDeliveryInfo: "",
    },
  });

  //Place to store the data to create overleveringsordre
  const [nydalenOrderData, setNydalenOrderData] = useState({
    storageItemIds: [""], // Initialize with default item ID
    type: "PICK_UP",
    orderDetails: {
      deliveryDate: "",
      deliveryTimeSlot: {
        from: "0900",
        to: "1700",
      },
      contactInfo: {
        firstName: "Joule",
        lastName: "AS",
        address: {
          street: "Økernveien 9",
          city: "Oslo",
          postalCode: "0653",
          countryCode: "NO", // Default to Norway
        },
        email: "haavard.traa@joule.no",
        mobilePhone: "+4795949356",
      },
      locale: "no-NO", // Set locale to no-NO
      extraDeliveryInfo: "overleveringsordre",
    },
  });

  const [data, setData] = useState({
    circulySubscriptionID: "",
    circulyOrderID: "",
    deliveryType: "nydalen", //nydalen eller wanda
    wandaType: "PICK_UP",
    deliveryDate: "",
    selectedTimeslot: "",
    postalCode: "",
    subscriptionEndDate: "",
  });

  //Validation function to make sure that phone number is in the right format
  const validatePhoneNumber = (phoneNumber) => {
    const regex = /^\+\d{10}$/; // Adjust the regex based on the exact format you need
    if (!regex.test(phoneNumber)) {
      setPhoneError("Phone number must be in the format +47XXXXXXXX");
    } else {
      setPhoneError(""); // Clear error if the phone number is valid
    }
  };

  //Retrieve the subscriptionID from the URL, and then perform a get request on the ReturnData from the Operations system as well as the WandaID
  useEffect(() => {
    // Parse the URL query parameters
    const queryParams = queryString.parse(search);

    // Immediately update your local state with the subscriptionID
    setData((currentData) => ({
      ...currentData,
      circulySubscriptionID: queryParams.subscriptionID,
    }));

    // Define the asynchronous function to fetch return and inventory information
    const fetchReturnAndInventoryInfo = async (subscriptionId) => {
      try {
        const returnInfoResponse = await axios.get(
          `/api/returns/subscription/${subscriptionId}`
        );
        const returnInfo = returnInfoResponse.data;

        // Fetch inventory item using serialNumber from the returned data
        const inventoryItemResponse = await axios.get(
          `/api/inventory/${returnInfo.serialNumber}`
        );
        const inventoryItem = inventoryItemResponse.data;

        // Update wandaOrderData with retrieved information
        setWandaOrderData((prevData) => ({
          ...prevData,
          storageItemIds: [inventoryItem.wandaID],
          orderDetails: {
            ...prevData.orderDetails,
            contactInfo: {
              firstName: returnInfo.firstName,
              lastName: returnInfo.lastName,
              address: {
                street: returnInfo.shippingStreet,
                city: returnInfo.shippingCity,
                postalCode: returnInfo.shippingPostalCode,
                countryCode: "NO",
              },
              email: returnInfo.email,
              mobilePhone: returnInfo.phone,
            },
          },
        }));

        // Update nydalen order data with retrieved wanda item id
        setNydalenOrderData((prevData) => ({
          ...prevData,
          storageItemIds: [inventoryItem.wandaID], // Update storageItemIds based on the fetched inventory item
        }));

        // After fetching the data, also update the data.postalCode with shippingPostalCode
        if (returnInfo.shippingPostalCode) {
          setData((currentData) => ({
            ...currentData,
            postalCode: returnInfo.shippingPostalCode,
          }));
        }

        // After fetching the data, also update the data.orderID with orderID, will be used for the one time transaction API
        if (returnInfo.orderId) {
          setData((currentData) => ({
            ...currentData,
            circulyOrderID: returnInfo.orderId,
          }));
        }

        if (returnInfo.subscriptionEndDate) {
          setData((currentData) => ({
            ...currentData,
            subscriptionEndDate: returnInfo.subscriptionEndDate,
          }));
        }

        // Validate that the phone number that is given from Circuly is in the correct format
        if (returnInfo.phone) {
          validatePhoneNumber(returnInfo.phone);
        }
      } catch (error) {
        console.error("Error fetching return or inventory information:", error);
        setErrorMsg(
          "Failed to fetch return or inventory information. Please try again."
        );
      }
    };

    // Call the fetch function if subscriptionID is available
    if (queryParams.subscriptionID) {
      fetchReturnAndInventoryInfo(queryParams.subscriptionID);
    }
  }, [search]); // The useEffect dependency array

  //Handle wanda order data change if customer wants to change their shipping address or phone number
  const handleWandaOrderDataChange = (field, value) => {
    setWandaOrderData((prevData) => {
      // Create a deep copy of the current state to avoid direct mutations
      const updatedData = JSON.parse(JSON.stringify(prevData));

      // Update the specific fields based on the field parameter
      switch (field) {
        case "mobilePhone":
          updatedData.orderDetails.contactInfo.mobilePhone = value;
          validatePhoneNumber(value); // Validate new phone number
          break;
        case "street":
          updatedData.orderDetails.contactInfo.address.street = value;
          break;
        case "city":
          updatedData.orderDetails.contactInfo.address.city = value;
          break;
        default:
          // Optionally handle an unrecognized field, though not strictly necessary
          console.warn(`Tried to update unrecognized field: ${field}`);
      }

      return updatedData;
    });
  };

  // Synchronize changes in `data` (deliveryDate, selectedTimeslot) to `wandaOrderData`
  useEffect(() => {
    setWandaOrderData((prevData) => ({
      ...prevData,
      orderDetails: {
        ...prevData.orderDetails,
        deliveryDate: data.deliveryDate,
        deliveryTimeSlot: data.selectedTimeslot
          ? {
              from: data.selectedTimeslot.from,
              to: data.selectedTimeslot.to,
            }
          : prevData.orderDetails.deliveryTimeSlot,
      },
    }));
  }, [data.deliveryDate, data.selectedTimeslot]);

  // Synchronize changes in `data` (deliveryDate) to `nydalenOrderData`
  useEffect(() => {
    // Check if data.deliveryDate is truthy and is a valid date
    if (data.deliveryDate && !isNaN(Date.parse(data.deliveryDate))) {
      const newDeliveryDate = new Date(data.deliveryDate);
      newDeliveryDate.setDate(newDeliveryDate.getDate() + 14); // Add 14 days to the selected delivery date
      const formattedDate = newDeliveryDate.toISOString().split("T")[0]; // Format to YYYY-MM-DD

      setNydalenOrderData((prevData) => ({
        ...prevData,
        orderDetails: {
          ...prevData.orderDetails,
          deliveryDate: formattedDate,
        },
      }));
    } else {
      // Optionally handle the case where deliveryDate is not set or invalid
      console.log("Delivery date is not set or is invalid");
    }
  }, [data.deliveryDate]); // This effect is dependent on changes to data.deliveryDate

  // Effect to update wandaOrderData.postalCode when data.postalCode changes
  useEffect(() => {
    if (data.postalCode) {
      // Reset delivery date and timeslot upon postal code change
      setData((prevData) => ({
        ...prevData,
        deliveryDate: "",
        selectedTimeslot: "",
      }));

      setWandaOrderData((prevData) => ({
        ...prevData,
        orderDetails: {
          ...prevData.orderDetails,
          contactInfo: {
            ...prevData.orderDetails.contactInfo,
            address: {
              ...prevData.orderDetails.contactInfo.address,
              postalCode: data.postalCode,
            },
          },
        },
      }));
    }
  }, [data.postalCode]); // React to changes in data.postalCode

  //Code that updates the count on bike movements from service in the dailymovementsummary collection
  const updateDailyMovementSummary = async () => {
    const requestBody = {
      bookingDate: data.deliveryDate,
      deliveryType: data.deliveryType, // "wanda" or "nydalen"
      movementType: "pickup",
      activityType: "cancellation",
    };

    try {
      await axios.post("/api/dailyMovements/update", requestBody);
      console.log("Daily Movement Summary Updated Successfully");
    } catch (error) {
      console.error("Error updating daily movement summary:", error);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true); // Start loading before the async operation
    try {
      // // Determine which order data to post based on the deliveryType
      // const orderDataToPost =
      //   data.deliveryType === "wanda" ? wandaOrderData : nydalenOrderData;

      // // Post the order data to Wanda's API
      // if (data.deliveryType === "wanda") {
      //   await axios.post("/api/wanda-order", orderDataToPost);
      // } else if (data.deliveryType === "nydalen") {
      //   try {
      //     await axios.post("/api/wanda-order", orderDataToPost);
      //   } catch (error) {
      //     // Log the error but do not treat it as critical if the delivery type is 'nydalen'
      //     console.warn(
      //       "Wanda API call failed for Nydalen delivery type, likely due to the status already being correct:",
      //       error.message
      //     );
      //   }
      // }

      // If the delivery type is 'wanda', make a one-time payment
      if (data.deliveryType === "wanda") {
        const paymentData = {
          amount: 188,
          order_id: data.circulyOrderID,
          message: `Kostnad for transport med Wanda den ${data.deliveryDate} i forbindelse med avslutning av abonnement`,
          products: [
            {
              product: "Wanda transport",
              amount: 188,
              tax_percent: 25,
              quantity: 1,
            },
          ],
        };

        // Trigger the one-time payment API
        await axios.post("/api/circuly/one-time-payment", paymentData);
      }

      // Update the return instance in the operations system with return method and return date
      const returnData = {
        deliveryType: data.deliveryType,
        deliveryDate: data.deliveryDate,
        ...(data.deliveryType === "wanda" && {
          selectedTimeslot: data.selectedTimeslot,
        }),
        phone: wandaOrderData.orderDetails.contactInfo.mobilePhone,
      };

      await axios.put(
        `/api/returns/update/${data.circulySubscriptionID}`,
        returnData
      );

      // Add to movement summary
      await updateDailyMovementSummary();

      // Navigate to confirmation page on success
      navigate("/return-confirmation", { state: { returnData: data } });
    } catch (error) {
      console.error("Error submitting data:", error);
      // Set error message to display to the user
      setErrorMsg(
        "Det oppstod dessverre en feil ved innsending av data, send oss en mail på hei@joule.no, så hjelper vi deg med tilbakelevering."
      );
    }
    setIsLoading(false); // Stop loading after the async operation is complete
  };

  // Determine if the submit button should be disabled
  const isSubmitDisabled =
    data.deliveryType === "nydalen"
      ? !data.deliveryDate
      : data.deliveryType === "wanda"
      ? !(data.deliveryDate && data.selectedTimeslot)
      : true;

  // Dynamically generating the button class abled/disabled submit button
  const buttonClass = `buttonClass w-38 tracking-wide font-bold rounded-lg inline-flex items-center justify-center py-2 px-6 ${
    isSubmitDisabled
      ? "bg-gray-200 text-gray-400 border-gray-200 cursor-not-allowed"
      : "bg-white text-black shadow-black shadow-md hover:shadow-black hover:shadow-lg hover:scale-105"
  }`;

  return (
    <div>
      <TopBar />
      <div className="w-full h-full min-h-full flex flex-col justify-center px-4 lg:px-8 z-100 mf:h-screen">
        <h2 className="mt-6 mb-4 text-center text-lg font-century-gothic text-jouletext">
          Velg metode for tilbakelevering av elsykkel
        </h2>
        <TypeOfDelivery
          data={data}
          setData={setData}
          deliveryTypeVarName="deliveryType"
          sourcePage="ReturnPage"
        />

        {data.subscriptionEndDate && (
          <div className="text-center text-jouletext my-4">
            Ditt abonnement utløper{" "}
            {format(parseISO(data.subscriptionEndDate), "d. MMMM yyyy", {
              locale: nb,
            })}
          </div>
        )}

        <CalendarComp data={data} setData={setData} sourcePage="ReturnPage" />

        {data.deliveryType === "wanda" && (
          <div className="flex flex-col items-center my-2">
            <input
              type="text"
              placeholder={`Telefonnummer: ${
                wandaOrderData.orderDetails.contactInfo.mobilePhone ||
                "Ikke oppgitt"
              }`}
              onChange={(e) =>
                handleWandaOrderDataChange("mobilePhone", e.target.value)
              }
              className="my-1 text-center py-2 w-full sm:w-2/3 md:w-1/2 rounded-lg border-2 border-gray-300 placeholder:text-gray-600"
            />
            {phoneError && <div className="text-red-500">{phoneError}</div>}
            <input
              type="text"
              placeholder={`Gateadresse: ${
                wandaOrderData.orderDetails.contactInfo.address.street ||
                "Ikke oppgitt"
              }`}
              onChange={(e) =>
                handleWandaOrderDataChange("street", e.target.value)
              }
              className="my-1 text-center py-2 w-full sm:w-2/3 md:w-1/2 rounded-lg border-2 border-gray-300 placeholder:text-gray-600"
            />
            <input
              type="text"
              placeholder={`Poststed: ${
                wandaOrderData.orderDetails.contactInfo.address.city ||
                "Ikke oppgitt"
              }`}
              onChange={(e) =>
                handleWandaOrderDataChange("city", e.target.value)
              }
              className="my-1 text-center py-2 w-full sm:w-2/3 md:w-1/2 rounded-lg border-2 border-gray-300 placeholder:text-gray-600"
            />
          </div>
        )}

        {errorMsg && <div className="text-red-400 text-center">{errorMsg}</div>}
        <div className="flex justify-center my-4 pb-8">
          <button
            onClick={handleSubmit}
            className={`${buttonClass} flex justify-center items-center`}
            disabled={isSubmitDisabled || isLoading}
          >
            {isLoading ? (
              <>
                <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-gray-900 mr-2"></div>
                Laster...
              </>
            ) : (
              "Registrer dato for retur"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReturnPage;
