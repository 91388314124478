import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import TopBar from "../components/TopBar";
import CalendarComp from "../components/CalendarComp";
import TypeOfDelivery from "../components/TypeOfDelivery";
import axios from "axios";

const ServiceReturnPage = () => {
  const { bookingId } = useParams(); // Extract the bookingId from the URL
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState({
    bookingId: bookingId || "",
    orderId: "",
    firstName: "",
    lastName: "",
    email: "",
    serialNumber: "",
    address: "",
    postalCode: "",
    city: "",
    wandaID: "",
    number: "", // Changed from phone to number
    deliveryType: "nydalen", // Default to Nydalen pickup
    wandaType: "DELIVERY", // Change to DELIVERY for the return process
    returnDate: "",
    selectedTimeslot: "",
  });

  const [wandaOrderData, setWandaOrderData] = useState({
    storageItemIds: [data.wandaID],
    type: "DELIVERY", // Set the type to DELIVERY instead of PICK_UP
    orderDetails: {
      deliveryDate: "",
      deliveryTimeSlot: {
        from: "",
        to: "",
      },
      contactInfo: {
        firstName: data.firstName,
        lastName: data.lastName,
        address: {
          street: data.address,
          city: data.city,
          postalCode: data.postalCode,
          countryCode: "NO",
        },
        email: data.email,
        mobilePhone: data.number, // Changed from phone to number
      },
      locale: "no-NO",
      extraDeliveryInfo: "",
    },
  });

  const [nydalenOrderData, setNydalenOrderData] = useState({
    storageItemIds: [data.wandaID],
    type: "DELIVERY", // Set the type to DELIVERY instead of PICK_UP
    orderDetails: {
      deliveryDate: "",
      deliveryTimeSlot: {
        from: "0900",
        to: "1700",
      },
      contactInfo: {
        firstName: "Joule",
        lastName: "AS",
        address: {
          street: "Økernveien 9",
          city: "Oslo",
          postalCode: "0653",
          countryCode: "NO",
        },
        email: "haavard.traa@joule.no",
        mobilePhone: "+4795949356",
      },
      locale: "no-NO",
      extraDeliveryInfo: "overleveringsordre",
    },
  });

  // Fetch the booking data from the server based on bookingId
  useEffect(() => {
    const fetchBookingData = async () => {
      try {
        const response = await axios.get(`/api/bookings/${bookingId}`);
        const bookingData = response.data;

        setData((prevData) => ({
          ...prevData,
          firstName: bookingData.firstName,
          lastName: bookingData.lastName,
          email: bookingData.email,
          serialNumber: bookingData.serialNumber,
          address: bookingData.addressStreet,
          postalCode: bookingData.addressPostalCode,
          city: bookingData.addressCity,
          wandaID: bookingData.wandaID,
          orderId: bookingData.orderId,
          number: bookingData.number, // Changed from phone to number
          deliveryType: bookingData.deliveryTypeToCustomer || "nydalen", // Set the default or use fetched data
        }));
      } catch (error) {
        console.error("Error fetching booking data:", error);
      }
    };

    if (bookingId) {
      fetchBookingData();
    }
  }, [bookingId]);

  // Synchronize delivery data with Wanda order data
  useEffect(() => {
    setWandaOrderData((prevData) => ({
      ...prevData,
      storageItemIds: [data.wandaID],
      orderDetails: {
        ...prevData.orderDetails,
        deliveryDate: data.returnDate, // Use returnDate instead of bookingDate
        deliveryTimeSlot: data.selectedTimeslot
          ? {
              from: data.selectedTimeslot.from,
              to: data.selectedTimeslot.to,
            }
          : prevData.orderDetails.deliveryTimeSlot,
        contactInfo: {
          ...prevData.orderDetails.contactInfo,
          firstName: data.firstName,
          lastName: data.lastName,
          address: {
            street: data.address,
            city: data.city,
            postalCode: data.postalCode,
            countryCode: "NO",
          },
          email: data.email,
          mobilePhone: data.number, // Changed from phone to number
        },
      },
    }));
  }, [
    data.returnDate,
    data.selectedTimeslot,
    data.wandaID,
    data.firstName,
    data.lastName,
    data.address,
    data.city,
    data.postalCode,
    data.email,
    data.number, // Changed from phone to number
  ]);

  // Synchronize delivery date with Nydalen order data
  useEffect(() => {
    if (data.returnDate && !isNaN(Date.parse(data.returnDate))) {
      const newDeliveryDate = new Date(data.returnDate);
      newDeliveryDate.setDate(newDeliveryDate.getDate() + 14);
      const formattedDate = newDeliveryDate.toISOString().split("T")[0];

      setNydalenOrderData((prevData) => ({
        ...prevData,
        storageItemIds: [data.wandaID],
        orderDetails: {
          ...prevData.orderDetails,
          deliveryDate: formattedDate,
        },
      }));
    }
  }, [data.returnDate, data.wandaID]);

  // Handle input changes for Wanda order data (address and city)
  const handleInputChange = (field, value) => {
    setData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  // Code that updates the count on bike movements from service in the dailymovementsummary collection
  const updateDailyMovementSummary = async () => {
    const requestBody = {
      bookingDate: data.returnDate, // Use returnDate for the summary update
      deliveryType: data.deliveryType, // "wanda" or "nydalen"
      movementType: "delivery",
      activityType: "service",
    };

    try {
      await axios.post("/api/dailyMovements/update", requestBody);
      console.log("Daily Movement Summary Updated Successfully");
    } catch (error) {
      console.error("Error updating daily movement summary:", error);
    }
  };

  // Function to handle submission of booking
  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      // const orderDataToPost =
      //   data.deliveryType === "wanda" ? wandaOrderData : nydalenOrderData;

      // // Post the order data to Wanda's API
      // await axios.post("/api/wanda-order", orderDataToPost);

      // If the delivery type is 'wanda', make a one-time payment
      if (data.deliveryType === "wanda") {
        const paymentData = {
          amount: 188,
          order_id: data.orderId,
          message: `Kostnad for transport med Wanda den ${data.returnDate} i forbindelse med gjennomført service`,
          products: [
            {
              product: "Wanda transport",
              amount: 188,
              tax_percent: 25,
              quantity: 1,
            },
          ],
        };

        // Trigger the one-time payment API
        await axios.post("/api/circuly/one-time-payment", paymentData);
      }

      // Update the booking with return details and send SMS confirmation
      await axios.put(`/api/bookings/${data.bookingId}/return-details`, {
        returnDate: data.returnDate,
        deliveryTypeToCustomer: data.deliveryType,
        number: data.number, // Use number instead of phone
      });

      // Update daily movement summary
      await updateDailyMovementSummary();

      // Navigate to confirmation page on success
      navigate("/service-booking/return-confirmation", {
        state: { bookingData: data },
      });
    } catch (error) {
      console.error("Error submitting data:", error);
      setErrorMsg(
        "There was an error submitting your booking. Please try again."
      );
    }
    setIsLoading(false);
  };

  // Disable submit button if conditions are not met
  const isSubmitDisabled =
    data.deliveryType === "nydalen"
      ? !data.returnDate
      : data.deliveryType === "wanda"
      ? !(data.returnDate && data.selectedTimeslot)
      : true;

  const buttonClass = `buttonClass w-38 tracking-wide font-bold rounded-lg inline-flex items-center justify-center py-2 px-6 ${
    isSubmitDisabled
      ? "bg-gray-200 text-gray-400 border-gray-200 cursor-not-allowed"
      : "bg-white text-black shadow-black shadow-md hover:shadow-black hover:shadow-lg hover:scale-105"
  }`;

  return (
    <div>
      <TopBar />
      <div className="w-full h-full min-h-full flex flex-col justify-center px-4 lg:px-8 z-100 mf:h-screen">
        <h2 className="mt-6 mb-4 text-center text-lg font-century-gothic text-jouletext">
          Velg Transportmetode
        </h2>

        <TypeOfDelivery
          data={data}
          setData={setData}
          deliveryTypeVarName="deliveryType"
          sourcePage="ServiceReturnPage"
        />

        <h2 className="mt-6 mb-4 text-center text-lg font-century-gothic text-jouletext">
          Velg Dato for Utlevering
        </h2>

        <CalendarComp
          data={data}
          setData={setData}
          sourcePage="ServiceReturnPage"
        />

        <div className="flex flex-col items-center my-2">
          {data.deliveryType === "wanda" && (
            <>
              <h3 className="my-2 text-center text-sm font-century-gothic text-jouletext">
                Adresse
              </h3>
              <input
                type="text"
                placeholder="Street Address"
                value={data.address}
                onChange={(e) => handleInputChange("address", e.target.value)}
                className="my-1 text-center py-2 w-full sm:w-2/3 md:w-1/2 rounded-lg border-2 border-gray-300 placeholder:text-gray-600"
              />
              <h3 className="my-2 text-center text-sm font-century-gothic text-jouletext">
                Sted
              </h3>
              <input
                type="text"
                placeholder="City"
                value={data.city}
                onChange={(e) => handleInputChange("city", e.target.value)}
                className="my-1 text-center py-2 w-full sm:w-2/3 md:w-1/2 rounded-lg border-2 border-gray-300 placeholder:text-gray-600"
              />
            </>
          )}
        </div>

        {errorMsg && <div className="text-red-400 text-center">{errorMsg}</div>}
        <div className="flex justify-center my-4 pb-8">
          <button
            onClick={handleSubmit}
            className={`${buttonClass} flex justify-center items-center`}
            disabled={isLoading || isSubmitDisabled}
          >
            {isLoading ? (
              <>
                <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-gray-900 mr-2"></div>
                Laster...
              </>
            ) : (
              "Bekreft Utlevering"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ServiceReturnPage;
